<template>
  <div class="kingMeal">
    <div v-title data-title="电竞保单"></div>

    <!-- <van-nav-bar
      title="白嫖套餐"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}/pcbb/bg-electronic.png`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>指定场馆:</p>
              <span style="color:var(--li-colortext);">IM电竞</span>
              <!-- <span>{{ kingMealData.mingc }}</span> -->
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">未开始</span>
            </div>
            <div class="title-item">
              <p>活动条件:</p>
              <span style="color:var(--li-colortext);">存款500元起，并且认证资料</span>
            </div>
            <div class="title-item">
              <p>存款时间:</p>
              <span style="color:var(--li-colortext);">指定赛事开始前24小时内</span>
            </div>
            <div class="title-item">
              <p>结算时间:</p>
              <span style="color:var(--li-colortext);">指定赛事完结后，次日24小时内系统自动发放</span>
            </div>

            <p style="
                        font-size: 13px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color:var(--li-colortext);
                        margin-top: 10px;
                        line-height: 1.5em;
                      ">
              活动期间，若会员投注于指定赛事结算后，根据本场赛事的负盈利可获得<span
                style="color: #418beb">100%本金返还，最高可达3888元</span>，仅需一倍流水，每周指定赛事，进行负盈利免单返还。
            </p>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td style="
                            color: #fff;
                            border-top-left-radius: 10px;
                            border-right: 1px solid #e2d2bf;
                          ">
                  会员等级
                </td>
                <td style="color: #fff; border-top-right-radius: 10px">
                  最高返还
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in kingMealData.bonus" :key="index">
                <td>{{ item.name }}</td>
                <td>{{ item.money }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                所有贝宝体育会员均可参与本活动。
              </li>
              <li>
                <span>2.</span>
                本活动期间，于指定存款时间内，进行一次性至少500元或以上存款，并完善资料绑定手机号、银行卡(或虚拟币钱包任选其一)等个人信息，投注指定赛事即可参与。
              </li>
              <li>
                <span>3.</span>
                本活动每场赛事只可申请一次保单，仅限于{{
                  kingMealData.mingc
                }}场馆下注指定赛事，不同场馆的注单不会统计。
              </li>
              <li>
                <span>4.</span>
                本活动VIP会员等级以红利派发时的系统等级为准。
              </li>
              <li>
                <span>5.</span>
                以上负盈利，仅对已结算并产生输赢结果的注单进行计算，任何串关、特殊投注、取消的赛事将不计算在内。需要在欧洲盘1.5～2.6或香港盘0.5～1.6水位的投注。负盈利计算仅限独赢、大小、单双三个盘口的全场与半场。任何注单提前兑现以及在同一赛事中同时投注对冲盘口，将不计算在内。
              </li>
              <li>
                <span>6.</span>
                本活动指定赛事结算后，若玩家于指定投注类型为负盈利，可依照对应的等级和比例获得返奖，满足申请条件的会员，系统会在24小时内进行派奖，相关部门审核完毕后发放，彩金仅需一倍流水即可取款，请注意查收。
              </li>
              <li>
                <span>7.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>8.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>9.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { KingMealInfoApi } from '../network/api'
export default {
  name: '',
  components: {},
  data () {
    return {
      kingMealData: '',
      cueTheme: '',
    }
  },

  mounted () {
    this.cueTheme = window.localStorage.getItem('cueTheme')
    this.KingMealInfoApi()
  },
  methods: {
    KingMealInfoApi () {
      KingMealInfoApi().then((res) => {
        if (res.status_code === 200) {
          this.kingMealData = res.message
        }
      })
    },
    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
  },
}
</script>

<style lang="less">
@import url('../assets/url.less');
@import '../assets/css/rulestyle.less';

body {
  background-color: #f6f7fc;
}

@import url('../assets/url.less');

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000 !important;
}

.kingMeal {

  // margin-top: 42px;
  .topBgc {
    margin: 0 10px;

    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 1px 6px 0px #d7dae2;

    .arrow {
      margin-top: 30px;
      margin-left: 20px;
      width: 25px;
    }

    .mathbox {
      font-family: PingFang SC;
      border: 1px solid #e9e9e9;
      position: relative;

      .finishpe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }

      .mathbox-item {
        display: flex;
        align-items: center;
        font-size: 12px;

        &:nth-child(1) {
          border-bottom: 1px solid #e9e9e9;
        }

        .title {
          padding: 15px 5px;
          height: 100%;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          background: linear-gradient(90deg, #3d73fa, #63b3fc);
          border-right: 1px solid #e9e9e9;
        }

        .content {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex: 1;
          text-align: center;
          font-family: PingFang SC;
          font-weight: 400;
          color: #283d68;

          img {
            width: 20px;
            vertical-align: middle;
          }
        }
      }
    }

    .rule {
      .rulebox {
        display: flex;
        align-items: center;

        >div {
          line-height: 1.5em;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;

          >img {
            max-width: 80px;
          }
        }
      }

      .primary_color {
        font-family: PingFang SC;
        font-weight: 400;
        color: #303030;
      }
    }
  }
}
</style>
