var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kingMeal"},[_c('div',{directives:[{name:"title",rawName:"v-title"}],attrs:{"data-title":"电竞保单"}}),_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":`${_vm.imgUrl}/pcbb/bg-electronic.png`}})]),_c('div',{staticClass:"rule-big-box"},[_c('div',{staticClass:"rule-top-img"},[(_vm.cueTheme == 'dattheme')?_c('img',{attrs:{"src":require("../assets/img/pcbb/rules_1.png")}}):_c('img',{attrs:{"src":`${_vm.imgUrl}pcbb/rules.png`}})]),_c('div',{staticClass:"rule-middle-img"},[_vm._m(0),_c('div',{staticClass:"rule-content"},[_c('div',{staticClass:"rule-content-title"}),_c('table',[_vm._m(1),_c('tbody',_vm._l((_vm.kingMealData.bonus),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.money))])])}),0)])]),_c('div',{staticClass:"rule-detail"},[_c('div',{staticClass:"rule-detail-title"}),_c('div',{staticClass:"activity_rules"},[_c('ul',[_vm._m(2),_vm._m(3),_c('li',[_c('span',[_vm._v("3.")]),_vm._v(" 本活动每场赛事只可申请一次保单，仅限于"+_vm._s(_vm.kingMealData.mingc)+"场馆下注指定赛事，不同场馆的注单不会统计。 ")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])])])]),_c('div',{staticClass:"rule-bottom-img"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule-condition"},[_c('div',{staticClass:"rule-condition-title"}),_c('div',{staticClass:"rule-acontents"},[_c('div',{staticClass:"title-item"},[_c('p',[_vm._v("活动对象:")]),_c('span',{staticStyle:{"color":"var(--li-colortext)"}},[_vm._v("贝宝体育全站会员")])]),_c('div',{staticClass:"title-item"},[_c('p',[_vm._v("指定场馆:")]),_c('span',{staticStyle:{"color":"var(--li-colortext)"}},[_vm._v("IM电竞")])]),_c('div',{staticClass:"title-item"},[_c('p',[_vm._v("活动时间:")]),_c('span',{staticStyle:{"color":"var(--li-colortext)"}},[_vm._v("未开始")])]),_c('div',{staticClass:"title-item"},[_c('p',[_vm._v("活动条件:")]),_c('span',{staticStyle:{"color":"var(--li-colortext)"}},[_vm._v("存款500元起，并且认证资料")])]),_c('div',{staticClass:"title-item"},[_c('p',[_vm._v("存款时间:")]),_c('span',{staticStyle:{"color":"var(--li-colortext)"}},[_vm._v("指定赛事开始前24小时内")])]),_c('div',{staticClass:"title-item"},[_c('p',[_vm._v("结算时间:")]),_c('span',{staticStyle:{"color":"var(--li-colortext)"}},[_vm._v("指定赛事完结后，次日24小时内系统自动发放")])]),_c('p',{staticStyle:{"font-size":"13px","font-family":"PingFang SC","font-weight":"400","color":"var(--li-colortext)","margin-top":"10px","line-height":"1.5em"}},[_vm._v(" 活动期间，若会员投注于指定赛事结算后，根据本场赛事的负盈利可获得"),_c('span',{staticStyle:{"color":"#418beb"}},[_vm._v("100%本金返还，最高可达3888元")]),_vm._v("，仅需一倍流水，每周指定赛事，进行负盈利免单返还。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticStyle:{"background":"linear-gradient(90deg, #4581fa, #63b3fc)"}},[_c('td',{staticStyle:{"color":"#fff","border-top-left-radius":"10px","border-right":"1px solid #e2d2bf"}},[_vm._v(" 会员等级 ")]),_c('td',{staticStyle:{"color":"#fff","border-top-right-radius":"10px"}},[_vm._v(" 最高返还 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("1.")]),_vm._v(" 所有贝宝体育会员均可参与本活动。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("2.")]),_vm._v(" 本活动期间，于指定存款时间内，进行一次性至少500元或以上存款，并完善资料绑定手机号、银行卡(或虚拟币钱包任选其一)等个人信息，投注指定赛事即可参与。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("4.")]),_vm._v(" 本活动VIP会员等级以红利派发时的系统等级为准。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("5.")]),_vm._v(" 以上负盈利，仅对已结算并产生输赢结果的注单进行计算，任何串关、特殊投注、取消的赛事将不计算在内。需要在欧洲盘1.5～2.6或香港盘0.5～1.6水位的投注。负盈利计算仅限独赢、大小、单双三个盘口的全场与半场。任何注单提前兑现以及在同一赛事中同时投注对冲盘口，将不计算在内。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("6.")]),_vm._v(" 本活动指定赛事结算后，若玩家于指定投注类型为负盈利，可依照对应的等级和比例获得返奖，满足申请条件的会员，系统会在24小时内进行派奖，相关部门审核完毕后发放，彩金仅需一倍流水即可取款，请注意查收。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("7.")]),_vm._v(" 本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("8.")]),_vm._v(" 本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',[_vm._v("9.")]),_vm._v(" 为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。 ")])
}]

export { render, staticRenderFns }